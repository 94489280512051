<template>
  <div class="wrapper">
    <button
      class="action"
      type="button"
      v-click-outside="vcoConfig"
      v-bind:class="{ opened: isVisible, disabled }"
      v-on:click="toggle"
    >
      <download-icon />
    </button>
    <div class="options" ref="optionsBox" v-if="isVisible">
      <ul>
        <li v-on:click="exportAs('png')">{{ $t("chart.export.save-png") }}</li>
        <li v-on:click="exportAs('jpg')">{{ $t("chart.export.save-jpg") }}</li>
        <li v-on:click="exportAs('pdf')">{{ $t("chart.export.save-pdf") }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vcoConfig: {
        handler: this.onClose,
        middleware: this.middleware,
        events: ['click'],
      },
      isVisible: false,
    };
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
    onClose() {
      this.isVisible = false;
    },
    middleware(event) {
      return !!this.$refs.optionsBox && !this.$refs.optionsBox.contains(event.target);
    },
    exportAs(type) {
      let mime;

      switch (type) {
        case 'pdf':
          mime = 'application/pdf';
          break;
        case 'png':
          mime = 'image/png';
          break;
        default:
          mime = 'image/jpeg';
      }

      this.$emit('export-chart', mime);
      this.isVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_download-options.scss";
</style>
